<template>
  <div v-if="hardnessIsLoaded">
    <Modal
      :title="{
        ru: 'Добавление твердости для всей детали',
        en: 'Add hardness to the entire part',
      }"
      :show="showInfoAllModal"
      @ok="closeModal('showInfoAllModal')"
      :okText="{
        ru: 'Ок',
        en: 'OK',
      }"
      :showCancel="false"
      @cancel="closeModal('showInfoAllModal')"
    >
      <template slot="body">
        <div>{{ tech.h_all_info_modal[language] }}</div>
      </template>
    </Modal>
    <Modal
      :title="{
        ru: 'Добавление твердости для выбранных поверхностей',
        en: 'Add Hardness to Selected Surfaces',
      }"
      :show="showInfoModal"
      @ok="closeModal('showInfoModal')"
      :okText="{
        ru: 'Ок',
        en: 'OK',
      }"
      :showCancel="false"
      @cancel="closeModal('showInfoModal')"
    >
      <template slot="body">
        <div>{{ tech.h_info_modal[language] }}</div>
      </template>
    </Modal>
    <Modal
      :title="{
        ru: 'Удаление группы поверхностей',
        en: 'Delete a surface group',
      }"
      :show="showDeleteModal"
      @ok="deleteGroup(deleteIndex)"
      :okText="{
        ru: 'Удалить',
        en: 'Delete',
      }"
      @cancel="closeModal('showDeleteModal')"
    >
      <template slot="body">
        <div>{{ tech.delete_modal[language] }}</div>
      </template>
    </Modal>
    <div class="tech-req__title">
      {{ tech.h_all_h[language] }}
    </div>
    <div class="tech-req__card">
      <div
        v-if="allHardnessColor"
        class="tech-req__card_color-line"
        :style="{
          backgroundColor: `rgb(${allHardnessColor.r * 255},${allHardnessColor.g * 255},${
            allHardnessColor.b * 255
          })`,
        }"
      ></div>
      <div style="width: 100%">
        <div class="tech-req__card_container">
          <div class="tech-req__card_title block block__between">
            <div>{{ tech.h_add_all_h[language] }}</div>
            <div>
              <Info @click="openModal('showInfoAllModal')" />
            </div>
          </div>
          <div v-if="allHardnessEdit && this.showEdit">
            <div class="tech-req__block_subtitle">
              {{ tech.h_hardness[language] }}
            </div>
            <div class="block block__between block__align-top">
              <div class="block__flex" style="margin-bottom: 20px">
                <div class="block block__align-center" :style="{ maxWidth: '100px' }">
                  <Select
                    :margin-bottom="0"
                    :max-width="115"
                    :height="35"
                    name="material_gost1"
                    show-arrow
                    :defaultValue="hardnessTypes[activeHardnessType - 1].label"
                    :values="hardnessTypes"
                    :dropdown-props="{
                      label: 'label',
                      value: 'id',
                    }"
                    @change="setHardnessType($event)"
                    class="tech-req__card_select"
                  />
                </div>
                <Input
                  class="component__margin-left"
                  type="number"
                  :step="1"
                  :height="35"
                  :max-width="50"
                  :margin-bottom="0"
                  @change="setAllHardnessValue($event, 'low')"
                  :defaultValue="
                    allLowerHardnessValue
                      ? allLowerHardnessValue
                      : hardnessTypes[activeHardnessType - 1].min
                  "
                  :max="hardnessTypes[activeHardnessType - 1].max"
                  :min="hardnessTypes[activeHardnessType - 1].min"
                />
                <div
                  class="tech-req__block_subtitle component__margin-left block__flex block__align-bottom block_center"
                >
                  ...
                </div>
                <Input
                  class="component__margin-left"
                  type="number"
                  :step="1"
                  :height="35"
                  :margin-bottom="0"
                  :max-width="50"
                  @change="setAllHardnessValue($event, 'up')"
                  :defaultValue="
                    allUpperHardnessValue
                      ? allUpperHardnessValue
                      : hardnessTypes[activeHardnessType - 1].max
                  "
                  :max="hardnessTypes[activeHardnessType - 1].max"
                  :min="hardnessTypes[activeHardnessType - 1].min"
                />
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="tech-req__block_subtitle" style="margin-bottom: 10px">
                {{ tech.h_depth[language] }}
              </div>
              <div class="block block__align-bottom">
                <Input
                  type="number"
                  :height="35"
                  :max-width="50"
                  :margin-bottom="0"
                  :defaultValue="allLowerDepthValue ? allLowerDepthValue : ''"
                  @change="setAllDepthValue($event, 'low')"
                />
                <div class="tech-req__block_subtitle" style="margin-left: 3px">
                  {{ tech.mm[language] }}
                </div>
                <div
                  class="tech-req__block_subtitle component__margin-left block__flex block__align-bottom block_center"
                >
                  ...
                </div>
                <Input
                  class="component__margin-left"
                  type="number"
                  :height="35"
                  :margin-bottom="0"
                  :max-width="50"
                  :defaultValue="allUpperDepthValue ? allUpperDepthValue : ''"
                  @change="setAllDepthValue($event, 'up')"
                />
                <div class="tech-req__block_subtitle" style="margin-left: 3px">
                  {{ tech.mm[language] }}
                </div>
              </div>
            </div>
            <Button
              type="primary"
              @click="saveAllHardness"
              block
              size="small"
              :text="tech.r_save[language]"
            />
          </div>
          <div v-if="allHardnessEdit === false || !this.showEdit">
            <div class="tech-req__block_subtitle">
              {{ tech.h_hardness[language] }}
            </div>
            <div class="block block__between block__align-center">
              <div
                class="tech-req__card_notactive_value"
                @click="
                  () => {
                    if (showEdit) {
                      allHardnessEdit = true;
                    }
                  }
                "
              >
                {{ hardnessTypes[activeHardnessType - 1].label }} {{ allLowerHardnessValue }} -
                {{ allUpperHardnessValue }}
              </div>
              <div
                v-if="(!allLowerDepthValue || !allUpperDepthValue) && showEdit"
                class="tech-req__card_notactive_change"
                @click="
                  () => {
                    if (showEdit) {
                      allHardnessEdit = true;
                    }
                  }
                "
              >
                {{ tech.r_change[language] }}
              </div>
            </div>
            <div
              v-if="allLowerDepthValue && allUpperDepthValue"
              style="margin-top: 10px"
              class="tech-req__block_subtitle"
            >
              {{ tech.h_depth[language] }}
            </div>
            <div
              v-if="allLowerDepthValue && allUpperDepthValue"
              class="block block__between block__align-center"
            >
              <div
                class="tech-req__card_notactive_value"
                @click="
                  () => {
                    if (showEdit) {
                      allHardnessEdit = true;
                    }
                  }
                "
              >
                {{ allLowerDepthValue }} - {{ allUpperDepthValue }}
              </div>
              <div
                v-if="showEdit"
                class="tech-req__card_notactive_change"
                @click="
                  () => {
                    if (showEdit) {
                      allHardnessEdit = true;
                    }
                  }
                "
              >
                {{ tech.r_change[language] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tech-req__left_params_title">
      {{ tech.h_surface_h[language] }}
    </div>
    <div v-if="hardness && hardness.groups">
      <div v-for="(group, index) in hardness.groups" v-bind:key="`group_${index}`">
        <div
          :class="`block tech-req__card ${index === activeHardness ? 'tech-req__card_active' : ''}`"
          style="align-items: stretch"
        >
          <div
            v-if="group.color"
            class="tech-req__card_color-line"
            :style="{
              backgroundColor: `rgb(${group.color.r * 255},${group.color.g * 255},${
                group.color.b * 255
              })`,
            }"
          ></div>
          <div style="width: 100%">
            <div
              class="block block__between block__align-center"
              style="padding: 15px 15px 7px 15px"
            >
              <div class="tech-req__card_title block block__between">
                <div class="block__flex block__align-bottom">
                  <div>{{ tech.r_surface_count[language] }}</div>
                  <div class="tech-req__card_count">
                    {{ group.surfaces.length }}
                  </div>
                </div>
                <div class="block__flex">
                  <Info @click="openModal('showInfoModal')" />
                  <Close
                    v-if="showEdit"
                    @click="openDeleteModal(index)"
                    :style="{ marginLeft: '7px' }"
                  />
                </div>
              </div>
            </div>
            <div v-if="index === activeHardness && showEdit" style="padding: 7px 15px 15px 15px">
              <div class="tech-req__block_subtitle" style="margin-bottom: 10px">
                {{ tech.h_hardness[language] }}
              </div>
              <div class="block block__between block__align-top">
                <div class="block__flex" style="margin-bottom: 20px">
                  <div class="block block__align-center" :style="{ maxWidth: '100px' }">
                    <Select
                      :margin-bottom="0"
                      :max-width="115"
                      :height="35"
                      :name="`hardness_g_${index}`"
                      show-arrow
                      :defaultValue="hardnessTypes[group.hardness_type - 1].label"
                      :values="hardnessTypes"
                      :dropdown-props="{
                        label: 'label',
                        value: 'id',
                      }"
                      @change="setSurfaceHardnessType($event, index)"
                      class="tech-req__card_select"
                    />
                  </div>
                  <Input
                    class="component__margin-left"
                    type="number"
                    :step="1"
                    :height="35"
                    :max-width="50"
                    :margin-bottom="0"
                    @change="setSurfaceHardness('low', $event, index)"
                    :defaultValue="
                      group.hardness_lower
                        ? group.hardness_lower
                        : hardnessTypes[group.hardness_type - 1].min
                    "
                    :max="hardnessTypes[group.hardness_type - 1].max"
                    :min="hardnessTypes[group.hardness_type - 1].min"
                  />
                  <div
                    class="tech-req__block_subtitle component__margin-left block__flex block__align-bottom block_center"
                  >
                    ...
                  </div>
                  <Input
                    class="component__margin-left"
                    type="number"
                    :step="1"
                    :height="35"
                    :margin-bottom="0"
                    :max-width="50"
                    @change="setSurfaceHardness('up', $event, index)"
                    :defaultValue="
                      group.hardness_upper
                        ? group.hardness_upper
                        : hardnessTypes[group.hardness_type - 1].max
                    "
                    :max="hardnessTypes[group.hardness_type - 1].max"
                    :min="hardnessTypes[group.hardness_type - 1].min"
                  />
                </div>
              </div>
              <div style="margin-bottom: 20px">
                <div class="tech-req__block_subtitle" style="margin-bottom: 10px">
                  {{ tech.h_depth[language] }}
                </div>
                <div class="block block__align-bottom">
                  <Input
                    type="number"
                    :height="35"
                    :max-width="50"
                    :margin-bottom="0"
                    :defaultValue="group.depth_lower ? group.depth_lower : ''"
                    @change="setDepthHardness('low', +$event, index)"
                  />
                  <div class="tech-req__block_subtitle" style="margin-left: 3px">
                    {{ tech.mm[language] }}
                  </div>
                  <div
                    class="tech-req__block_subtitle component__margin-left block__flex block__align-bottom block_center"
                  >
                    ...
                  </div>
                  <Input
                    class="component__margin-left"
                    type="number"
                    :height="35"
                    :margin-bottom="0"
                    :max-width="50"
                    :defaultValue="group.depth_upper ? group.depth_upper : ''"
                    @change="setDepthHardness('up', +$event, index)"
                  />
                  <div class="tech-req__block_subtitle" style="margin-left: 3px">
                    {{ tech.mm[language] }}
                  </div>
                </div>
              </div>
              <Button
                type="primary"
                @click="saveHardness"
                block
                size="small"
                :text="tech.r_save[language]"
              />
            </div>
            <div v-if="index !== activeHardness || !showEdit" style="padding: 15px 7px 15px 15px">
              <div
                v-if="group.hardness_lower && group.hardness_upper && group.hardness_type"
                class="tech-req__block_subtitle"
              >
                {{ tech.h_hardness[language] }}
              </div>
              <div class="block block__between block__align-center">
                <div
                  class="tech-req__card_notactive_value"
                  v-if="group.hardness_lower && group.hardness_upper && group.hardness_type"
                  @click="changeHardnessGroup(index)"
                >
                  {{ hardnessTypes[group.hardness_type - 1].label }} {{ group.hardness_lower }} -
                  {{ group.hardness_upper }}
                </div>
                <div
                  v-if="(!group.depth_lower || !group.depth_upper) && showEdit"
                  class="tech-req__card_notactive_change"
                  @click="changeHardnessGroup(index)"
                >
                  {{ tech.r_change[language] }}
                </div>
              </div>
              <div
                v-if="group.depth_lower && group.depth_upper"
                style="margin-top: 10px"
                class="tech-req__block_subtitle"
              >
                {{ tech.h_depth[language] }}
              </div>
              <div
                v-if="group.depth_lower && group.depth_upper"
                class="block block__between block__align-center"
              >
                <div class="tech-req__card_notactive_value" @click="changeHardnessGroup(index)">
                  {{ group.depth_lower }} - {{ group.depth_upper }}
                </div>
                <div
                  v-if="showEdit"
                  class="tech-req__card_notactive_change"
                  @click="changeHardnessGroup(index)"
                >
                  {{ tech.r_change[language] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="`tech-req__card tech-req__card_plus`" v-if="!allHardnessEdit && showEdit">
      <div v-if="activeAdd" @click="addHardness">
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ tech.h_surface_add[language] }}
          </div>
        </div>
      </div>
      <div v-if="!activeAdd">
        <div class="block block__center block__column block__align-center">
          <div class="tech-req__card_plus_text">
            {{ tech.r_surface_select[language] }}
          </div>
          <div
            class="tech-req__card_plus_text tech-req__card_plus_cancel"
            @click="cancelAddHardness"
          >
            {{ tech.r_cancel[language] }}
          </div>
        </div>
      </div>
    </div>
    <div :class="`tech-req__card`" v-if="allHardnessEdit">
      <div class="block block__center block__column block__align-center">
        <div class="tech-req__card_plus_text">
          {{ tech.h_for_add_surface[language] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from "@/utilities/axios";
import tech from "@/assets/text/tech.json";
import Input from "@/components/input/Input.vue";
import Button from "@/components/button/Button.vue";

const Info = () => import("@/assets/images/tech/info.svg");
const Select = () => import("@/components/select/Select.vue");
const Modal = () => import("@/components/modal/Modal.vue");
const Plus = () => import("@/assets/images/tech/plus.svg");
const Close = () => import("@/assets/images/tech/close.svg");

export default {
  name: "HardnessV2",
  data() {
    return {
      tech,
      unsubscribe: () => {},
      showEdit: true,
      modelIsLoaded: false,
      hardnessIsLoaded: false,
      hardness: null,
      hardnessRa: null,
      hardnessRz: null,
      hardnessGroups: [],
      flatHardness: {},
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      hideColor: { r: 0.7529, g: 0.7529, b: 0.7529 },
      activeGroup: [],
      features: null,
      processedFeatures: [],
      flatFeatures: {},
      kteClick: false,
      activeClick: false,
      showInfoAllModal: false,
      showInfoModal: false,
      showDeleteModal: false,
      allHardnessEdit: true,
      activeHardnessType: 1,
      allUpperHardnessValue: null,
      allLowerHardnessValue: null,
      allUpperDepthValue: null,
      allLowerDepthValue: null,
      showPlus: false,
      partDataHardness: null,
      activeAdd: true,
      activeHardness: null,
      deleteIndex: null,
      allHardnessColor: null,
      hardnessTypes: [
        {
          id: 1,
          label: "HB",
          min: 70,
          max: 800,
        },
        {
          id: 2,
          label: "HRA",
          min: 40,
          max: 90,
        },
        {
          id: 3,
          label: "HRB",
          min: 30,
          max: 100,
        },
        {
          id: 4,
          label: "HRC",
          min: 10,
          max: 80,
        },
        {
          id: 5,
          label: "HV",
          min: 100,
          max: 2000,
        },
        {
          id: 6,
          label: "HSA",
          min: 10,
          max: 150,
        },
        {
          id: 7,
          label: "HSD",
          min: 10,
          max: 150,
        },
      ],
    };
  },
  props: {
    partData: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    partData: function (val) {
      this.partDataHardness = val;
    },
    activeHardness: function () {
      this.setFlatHardness();
    },
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    getFeatures() {
      query(
        "post",
        "getFeaturesByPart",
        {
          time_stamp: this.partData.time_stamp,
          hash: this.partData.hash,
          file_name: this.partData.file_name,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then((result) => {
        this.features = result.features_result;
        for (const [index, feature] of result.features_result.entries()) {
          const result = [];
          if (feature.faces) {
            for (const item of feature.faces) {
              this.flatFeatures[`Face${item}`] = index;
              result.push(`Face${item}`);
            }
          }
          this.processedFeatures.push(result);
        }
      });
    },
    getHardness() {
      return new Promise((resolve) => {
        query(
          "post",
          "getTechDataByPartOrder",
          {
            hardness: true,
            uuid: this.$route.params.uuid,
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((result) => {
          this.hardness = result.hardness;
          if (result.hardness && result.hardness.groups) {
            this.hardnessGroups = result.hardness.groups;
            for (const [index, group] of result.hardness.groups.entries()) {
              for (const surface of group.surfaces) {
                this.flatHardness[surface] = index;
              }
            }
          }
          resolve();
        });
      });
    },
    surfaceClicked(name) {
      if (this.activeClick) {
        const removeFromGroup = (face) => {
          const groups = this.hardnessGroups.slice();
          const editableGroup = groups[this.flatHardness[face]].surfaces;
          const index = editableGroup.findIndex((el) => el === face);
          editableGroup.splice(index, 1);
          this.hardnessGroups = groups;
        };
        const addInGroup = (face) => {
          const groups = this.hardnessGroups.slice();
          const editableGroup = groups[this.flatHardness[face]].surfaces;
          editableGroup.push(face);
          this.hardnessGroups = groups;
        };
        const index = this.activeGroup.findIndex((el) => el === name);
        if (index >= 0) {
          if (this.kteClick) {
            for (const face of this.processedFeatures[this.flatFeatures[name]]) {
              const faceIndex = this.activeGroup.findIndex((el) => el === face);
              if (this.flatHardness[face] && this.flatHardness[face] !== this.activeHardness) {
                addInGroup(face);
              }
              if (faceIndex >= 0) {
                this.activeGroup.splice(faceIndex, 1);
              }
            }
          } else {
            if (
              (this.flatHardness[name] || this.flatHardness[name] === 0) &&
              this.flatHardness[name] !== this.activeHardness
            ) {
              addInGroup(name);
            }
            this.activeGroup.splice(index, 1);
          }
        } else {
          if (this.kteClick) {
            this.activeGroup = [
              ...this.activeGroup,
              ...this.processedFeatures[this.flatFeatures[name]],
            ];
            if (this.flatHardness[name] && this.flatHardness[name] !== this.activeHardness) {
              for (const face of this.processedFeatures[this.flatFeatures[name]]) {
                removeFromGroup(face);
              }
            }
          } else {
            if (
              (this.flatHardness[name] || this.flatHardness[name] === 0) &&
              this.flatHardness[name] !== this.activeHardness
            ) {
              removeFromGroup(name);
            }
            this.activeGroup.push(name);
          }
        }
        const groups = this.hardnessGroups.slice();
        groups[this.activeHardness].surfaces = this.activeGroup;
        this.hardnessGroups = groups;
        const result = [
          {
            surfaces: this.activeGroup,
            color: this.clickColor,
          },
        ];
        this.$store.state.modelV2.decorateSurfaces(result);
      }
    },
    colorGroups() {
      if (this.hardnessGroups) {
        const groups = this.hardnessGroups.slice();
        if (this.hardness && this.hardness.all) {
          groups.push({
            ...this.hardness.all,
            ...{
              surfaces: "all",
            },
          });
        }
        if (groups) {
          groups.sort((a, b) => b.value - a.value);
          groups.forEach((value, index) => {
            let percent = 0;
            if (groups.length > 1) {
              percent = (1 / (groups.length - 1)) * index;
            }
            const color = this.setColor(percent);
            if (value.surfaces === "all") {
              this.allHardnessColor = color;
            }
            groups[index].color = color;
          });
        }
        this.$store.state.modelV2.decorateSurfaces(groups);
        this.$store.state.modelV2.decorateViewer(groups);
      }
    },
    setFlatHardness() {
      for (const [index, group] of this.hardnessGroups.entries()) {
        for (const surface of group.surfaces) {
          this.flatHardness[surface] = index;
        }
      }
    },
    setColor(percent) {
      const zero = 0.5;
      const one = 0.89;
      const change = one - zero;
      const summ = one + zero;
      const color = {
        r: 0,
        g: 0,
        b: 0,
      };
      if (percent >= 0 && percent <= 0.25) {
        color.r = zero;
        color.g = +(zero + (percent / 0.25) * change).toFixed(3);
        color.b = one;
      } else if (percent > 0.25 && percent <= 0.5) {
        color.r = zero;
        color.g = one;
        color.b = +(summ - (zero + (percent / 0.5) * change)).toFixed(3);
      } else if (percent > 0.5 && percent <= 0.75) {
        color.r = +(zero + (percent / 0.75) * change).toFixed(3);
        color.g = one;
        color.b = zero;
      } else {
        color.r = one;
        color.g = +(summ - (zero + percent * change)).toFixed(3);
        color.b = zero;
      }
      return color;
    },
    openModal(modalName) {
      this[modalName] = true;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setHardnessType(item) {
      this.activeHardnessType = item.value;
      this.allLowerHardnessValue = this.hardnessTypes[item.value - 1].min;
      this.allUpperHardnessValue = this.hardnessTypes[item.value - 1].max;
    },
    setAllHardnessValue(data, type) {
      if (type === "up") {
        this.allUpperHardnessValue = data;
      } else if (type === "low") {
        this.allLowerHardnessValue = data;
      }
    },
    setAllDepthValue(data, type) {
      if (type === "up") {
        this.allUpperDepthValue = data;
      } else if (type === "low") {
        this.allLowerDepthValue = data;
      }
    },
    saveAllHardness() {
      this.allHardnessEdit = false;
      this.showPlus = true;
      this.saveData();
      // Тут сохранение и разукрашка
    },
    closeModal(modalName) {
      this[modalName] = false;
    },
    saveData() {
      const groups = this.hardnessGroups;
      const hardness = {
        all: {
          hardness_type: this.activeHardnessType,
          hardness_upper: this.allUpperHardnessValue
            ? this.allUpperHardnessValue
            : this.hardnessTypes[this.activeHardnessType - 1].min,
          hardness_lower: this.allLowerHardnessValue
            ? this.allLowerHardnessValue
            : this.hardnessTypes[this.activeHardnessType - 1].max,
          depth_upper: this.allUpperDepthValue ? this.allUpperDepthValue : null,
          depth_lower: this.allLowerDepthValue ? this.allLowerDepthValue : null,
        },
        groups,
      };
      query(
        "post",
        "changeTechDataByPartOrder",
        {
          hash: this.partDataHardness.hash,
          time_stamp: this.partDataHardness.time_stamp,
          file_name: this.partDataHardness.file_name,
          uuid: this.$route.params.uuid,
          hardness,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        this.getHardness();
      });
    },
    addHardness() {
      this.activeAdd = false;
      this.activeClick = true;
      this.hardnessGroups.push({
        hardness_id: 10,
        hardness_type: 1,
        hardness_upper: this.hardnessTypes[0].max,
        hardness_lower: this.hardnessTypes[0].min,
        surfaces: [],
      });
      this.$store.state.modelV2.clearSurfaces();
      this.activeGroup = [];
      this.activeHardness = this.hardnessGroups.length - 1;
    },
    cancelAddHardness() {
      this.activeAdd = true;
      this.colorGroups();
    },
    openDeleteModal(index) {
      this.deleteIndex = index;
      this.showDeleteModal = true;
    },
    setSurfaceHardnessType(value, index) {
      // const previous_type = this.hardnessGroups[index].hardness_type;
      this.hardnessGroups[index].hardness_type = value.value;
      this.hardnessGroups[index].hardness_lower = this.hardnessTypes[value.value - 1].min;
      this.hardnessGroups[index].hardness_upper = this.hardnessTypes[value.value - 1].max;
    },
    setSurfaceHardness(type, value, index) {
      if (type === "up") {
        this.hardnessGroups[index].hardness_upper = value;
      } else if (type === "low") {
        this.hardnessGroups[index].hardness_lower = value;
      }
    },
    setDepthHardness(type, value, index) {
      if (type === "up") {
        this.hardnessGroups[index].depth_upper = value;
      } else if (type === "low") {
        this.hardnessGroups[index].depth_lower = value;
      }
    },
    saveHardness() {
      const hardnessLower = this.hardnessGroups[this.activeHardness].hardness_lower;
      const hardnessUpper = this.hardnessGroups[this.activeHardness].hardness_upper;
      const hardnessType = this.hardnessGroups[this.activeHardness].hardness_type;
      if (this.hardnessGroups) {
        for (const [index, group] of this.hardnessGroups.entries()) {
          if (
            index !== this.activeHardness &&
            hardnessLower === group.hardness_lower &&
            hardnessUpper === group.hardness_upper &&
            hardnessType === group.hardness_type
          ) {
            this.hardnessGroups[this.activeHardness].surfaces = [
              ...this.hardnessGroups[this.activeHardness].surfaces,
              ...group.surfaces,
            ];
            this.hardnessGroups.splice(index, 1);
          }
          if (group.surfaces.length === 0) {
            this.hardnessGroups.splice(index, 1);
          }
        }
      }
      this.activeClick = false;
      this.activeGroup = [];
      this.activeHardness = null;
      this.activeAdd = true;
      this.colorGroups();
      this.saveData();
    },
    changeHardnessGroup(index) {
      if (this.showEdit) {
        this.activeAdd = false;
        this.activeHardness = index;
        this.activeClick = true;
        this.activeGroup = this.hardnessGroups[index].surfaces;
        const surfaces = [
          {
            surfaces: this.hardnessGroups[index].surfaces,
            color: this.clickColor,
          },
        ];
        this.$store.state.modelV2.decorateSurfaces(surfaces);
      }
    },
    deleteGroup(index) {
      this.hardnessGroups.splice(index, 1);
      this.saveData();
      this.colorGroups();
      this.activeClick = false;
      this.setFlatHardness();
      this.activeHardness = null;
      this.showDeleteModal = false;
      if (this.activeHardness === null) {
        this.activeAdd = true;
      }
    },
  },
  mounted() {
    this.showEdit = this.edit;
    if (!this.showEdit) {
      this.allHardnessEdit = false;
    }
    this.$store.state.modelV2.changeSelectorType("component");
    this.partDataHardness = this.partData;
    this.$store.commit("modelV2/setSurfaceClick", this.surfaceClicked);
    this.modelIsLoaded = this.$store.state.modelV2.modelReady;
    Promise.all([this.getHardness()]).then(() => {
      if (this.modelIsLoaded) {
        this.colorGroups();
      }
      if (this.hardness && this.hardness.all) {
        this.allUpperHardnessValue = this.hardness.all.hardness_upper
          ? this.hardness.all.hardness_upper
          : null;
        this.allLowerHardnessValue = this.hardness.all.hardness_lower
          ? this.hardness.all.hardness_lower
          : null;
        this.activeHardnessType = this.hardness.all.hardness_type;
        this.allLowerDepthValue =
          this.hardness.all.depth_lower || this.hardness.all.depth_lower === 0
            ? this.hardness.all.depth_lower
            : null;
        this.allUpperDepthValue =
          this.hardness.all.depth_upper || this.hardness.all.depth_upper === 0
            ? this.hardness.all.depth_upper
            : null;
        this.allHardnessEdit = false;
      }
      this.hardnessIsLoaded = true;
    });
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "modelV2/setModelReady") {
        this.modelIsLoaded = mutation.payload;
        if (this.hardnessIsLoaded) {
          this.colorGroups();
        }
      }
    });
    // this.getFeatures();
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.state.modelV2.decorateViewer(null);
    this.$store.state.modelV2.clearSurfaces();
  },
  components: {
    Info,
    Select,
    Modal,
    Plus,
    Close,
    Input,
    Button,
  },
};
</script>
